import React from 'react';
import { ReactComponent as CompanyIcon } from './assets/crown.svg';

import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const App = () => {
    return (
        <WhatsAppWidget
            message={`Olá! 👋🏼 \n\nO que podemos fazer por você?`}
            phoneNumber="5586999060047"
        />
    );
};

export default App;
